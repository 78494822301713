import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify'; // Import toast
import 'react-toastify/dist/ReactToastify.css';
import "./MyFormModal.css";

function MyFormModal({ closeModal }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    message: '',
  });
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'contact') {
      const isValidInput = /^[+\-\d\s]*$/.test(value);
      if (isValidInput) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  
  const handleKeyDown = (e) => {
    if (e.target.name === "contact") {
      const allowedKeys = ['+', '-', ' ', ...Array.from(Array(10).keys()).map(String), 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
      if (!allowedKeys.includes(e.key)) {
        e.preventDefault();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    
    // Parse the contact field to an integer
    const parsedFormData = {
      ...formData,
      contact: parseInt(formData.contact),
    };

    try {
      const response = await fetch('https://stg.qsense.ai.recosenselabs.com/api/authn/contact_us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(parsedFormData),
      });

      const responseData = await response.json();

      if (responseData.status === 1) {
        toast.success('Form submitted successfully!'); // Toast success message
        closeModal();
      } else {
        console.error('Failed to submit form:', responseData);
        toast.error('Failed to submit form. Please try again later.'); // Toast error message
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form. Please try again later.'); // Toast error message
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    document.body.classList.add('open-modal');
    return () => {
      document.body.classList.remove('open-modal');
    };
  }, []); // This effect runs once when the component mounts

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <h1 style={{  textAlign: 'center' }}><b>INITIAL DISCUSSION</b></h1>
      <button className="close-btn" style={{ display: 'flex' }} onClick={closeModal}><b>X</b></button>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          Contact:
          <input
            type="text"
            name="contact"
            value={formData.contact}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            required 
          />
        </label>
        <br />
        <label>
          Message:
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </label>
        <br />
        <button type="submit" disabled={submitting}>Submit</button>
      </form>
    </Modal>
  );
}

export default MyFormModal;
