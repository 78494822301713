import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import DownloadApp from "components/cta/DownloadApp.js";
import Header from "../headers/light.js";
import video2 from "../../images/qsvid2.mp4"
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "../../images/design-illustration.svg";
import RestaurantLandingPage from "demos/QsenseLandingPage.js";

const Container = tw.div`relative container mx-auto`;
const TwoColumn = tw.div`flex flex-col md:flex-row md:items-center  mx-auto pt-20 pb-10 md:pt-24 md:pb-12`;
const LeftColumn = tw.div`relative w-full md:w-2/5 text-center md:text-left`;
const RightColumn = tw.div`relative w-full md:w-3/5 mt-12 md:mt-0 flex flex-col justify-center`;


const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl w-full`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg  lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded  bg-[#1E91FF] text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const WatchVideoButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default ({
  
  heading = "Modern React Templates, Just For You",
 description="Our templates are easy to setup, understand and customize. Fully modular components with a variety of pages and components.",
  primaryButtonText="Get Started",
  // primaryButtonUrl="https://qsense.ai/app/",
  primaryButtonOnClick,
  // watchVideoButtonText="Watch Video",
  watchVideoButtonOnClick,
  // watchVideoYoutubeUrl="https://www.youtube.com/embed/3FltO3Pk4rc",
  imageSrc=DesignIllustration,
  imageCss=null,
  imageDecoratorBlob = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);
  const LeftColumnWithoutPaddingRight = tw(LeftColumn)`
  // This is the CSS-in-JS way of styling. You can remove right padding here.
  md:pr-0
`;
  return (
    <>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>{heading}</Heading>
            <Paragraph >{description}</Paragraph>
            <Actions>
              <PrimaryButton onClick={primaryButtonOnClick}>
              <span className="Grt Started">{primaryButtonText}</span>

                </PrimaryButton>
              <WatchVideoButton onClick={watchVideoButtonOnClick}>
                <span className="playIconContainer">
                  {/* <PlayIcon className="playIcon" /> */}
                </span>
                {/* <span className="playText">{watchVideoButtonText}</span> */}
              </WatchVideoButton>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              {/* <DownloadApp/> */}
              <video 
              src={video2} 
              autoPlay 
              muted
              loop 
              style={{ 
                maxWidth: '100%', 
                height: '100%', 
                margin: 'auto', 
                borderRadius: '10px', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
              }}
            ></video>


              {/* <img
                css={imageCss}
                src={imageSrc}
                alt="Hero"
              /> */}
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
            <ResponsiveVideoEmbed onClick={watchVideoButtonOnClick} tw="w-full" />
          </div>
        </StyledModal>
      </Container>
    </>
  );
};
