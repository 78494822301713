import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import styles from "../../demos/Card.module.css";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import "../../demos/Card.module.css";
import { Link } from 'react-router-dom';

import logo from "../../images/logo.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import logo2 from "../../images/RecoSenseLogo.png";
import MyFormModal from "../headers/MyFormModal";
import OpenModal from "../headers/MyFormModal";
const Header = tw.header`
flex justify-between items-center
mx-auto
`;

const Container = tw.div`container mx-auto`;

export const NavLinks = tw.div`inline-block`;

export const NavLink = tw.a`
`;

export const PrimaryLink = tw(NavLink)`
lg:mx-0
px-5 py-2 rounded bg-[#1E91FF] text-gray-100
hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
border-b-0
`;

const ContactForm = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const SecondaryLink = tw(NavLink)`
lg:mx-0
px-8 py-3 rounded bg-[#1E91FF] text-gray-100
hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
hidden lg:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  const [showContactForm, setShowContactForm] = useState(false);

  const onClickBlog = () => {
    const url = process.env.REACT_APP_BLOG;
    window.open(url, "_blank");
  };

  const onClickLogin = () => {
    const url = process.env.REACT_APP_LOGIN;
    window.open(url, "_blank");
  };

  const defaultLinks = [
    <div>
      <NavLinks tw=" flex gap-5" key={1}>
        <a
          href={process.env.REACT_APP_BLOG}
          target="_blank"
          className="tooltip-container"
          tw="cursor-pointer flex justify-center items-center py-2 px-5 border border-blue-500 text-blue-500 font-semibold rounded-md hover:bg-blue-500 hover:text-white"
          rel="noreferrer"
        >
          Blog
          {/* <span className="tooltip">blog.qsense.ai</span> */}
        </a>

        <a
          href={process.env.REACT_APP_LOGIN}
          target="_blank"
          className="tooltip-container"
          tw="cursor-pointer flex justify-center items-center py-2 px-5 border border-blue-500 text-blue-500 font-semibold rounded-md hover:bg-blue-500 hover:text-white"
          rel="noreferrer"
        >
          Login
          {/* <span className="tooltip">app.qsense.ai/login</span> */}
        </a>

        <PrimaryLink
          tw="cursor-pointer"
          css={roundedHeaderButton && tw` px-5 font-bold rounded-full`}
          onClick={() => setShowContactForm(true)} // Show contact form on click
        >
          Book a Demo
        </PrimaryLink>
      </NavLinks>
    </div>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];
  const SubText = styled.div`
    font-size: 14px; /* Smaller font size for "By RecoSense" */
  `;
  const text = styled.div`
    font-size: 24px; /* Smaller font size for "By RecoSense" */
  `;
  const Logo = styled.img``;
  const defaultLogoLink = (
    <Link to="/">
      <LogoLink>
      <Logo
        style={{ height: "48px", width: "48px" }}
        src={logo2}
        alt="Qsense"
      />
      <div>
        <text>
          {" "}
          Qsense<span tw="text-sm ">.ai</span>
        </text>
        {/* <SubText>By RecoSense</SubText> */}
      </div>
      </LogoLink>
    </Link>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header
      style={{
        padding: ".5rem 1rem",
        background: "white",
        position: "sticky",
        top: "0",
        zIndex: "1000",
        maxWidth: "100vw",
        boxShadow: "0 2px 6px -1px rgba(0, 0, 0, 0.2)",
      }}
      className={className || "header-light"}
    >
      <Container>
        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          {logoLink}
          {links}
        </DesktopNavLinks>

        <MobileNavLinksContainer
          css={collapseBreakpointCss.mobileNavLinksContainer}
        >
          {logoLink}
          <MobileNavLinks
            initial={{ x: "150%", display: "none" }}
            animate={animation}
            css={collapseBreakpointCss.mobileNavLinks}
          >
            {links}
          </MobileNavLinks>
          <NavToggle
            onClick={toggleNavbar}
            className={showNavLinks ? "open" : "closed"}
          >
            {showNavLinks ? (
              <CloseIcon tw="w-6 h-6" />
            ) : (
              <MenuIcon tw="w-6 h-6" />
            )}
          </NavToggle>
        </MobileNavLinksContainer>
      </Container>

      {/* Popup Contact Form */}
      {showContactForm && (
        <MyFormModal closeModal={() => setShowContactForm(false)} />
      )}
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
