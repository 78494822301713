import React from "react";
import "./DynamicTable.css"; // Import your CSS file for styling
class DynamicTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: 5,
      columns: 10,
      data: this.generateEmptyData(10, 5),
    };
  }

  // Function to generate empty data for given rows and columns
  generateEmptyData(rows, columns) {
    const data = [];
    for (let i = 0; i < rows; i++) {
      const rowData = [];
      for (let j = 0; j < columns; j++) {
        rowData.push("");
      }
      data.push(rowData);
    }
    return data;
  }

  // Function to handle changes in cell content
  handleCellChange = (rowIndex, colIndex, event) => {
    const newData = [...this.state.data];
    newData[rowIndex][colIndex] = event.target.value;
    this.setState({ data: newData });
  };

  render() {
    return (
      <div tw="container" className="table-container">
        <table tw='rounded-t-lg' className="comparison-table rounded-t-lg">
          <thead >
          <th >&nbsp;</th>
            <th
            style={{
              backgroundColor: "black",
              color: "white",
              // borderTopRightRadius: "10px",
            }}
            >Qsense.ai</th>
            <th>RPA / OCR Tools</th>
            <th
              
            >
              Public GPT Models and Paid LLMs
            </th>
          </thead>
          <tbody>
            <tr>
              <td>
                Support for multiformat files <br />
                - Digital PDF
                <br />
                - Scanned PDF <br />
                - Images <br />- Spreadsheets
              </td>
              <td>
                <div class="circle-container">
                  <div class="circle">
                    <div class="checkmark"></div>
                  </div>
                </div>
                No need of any training data{" "}
              </td>
              <td>
                <div className="circle-container">
                  <div className="circles">
                    <div className="checkmark-secondary"></div>
                  </div>
                </div>
                Partially supported without context
              </td>

              <td>
                <div class="circle-container">
                  <div class="circless">
                    <div class="checkmark-Third"></div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Processing of multiple financial reports <br/>
                – Tax statements,
                Invoices, Contracts, Annual filings
              </td>
              <td>
                <div class="circle-container">
                  <div class="circle">
                    <div class="checkmark"></div>
                  </div>
                </div>
              </td>
              <td>
                <div className="circle-container">
                  <div className="circles">
                    <div className="checkmark-secondary"></div>
                  </div>
                </div>
              </td>

              <td><div class="circle-container">
                  <div class="circless">
                    <div class="checkmark-Third"></div>
                  </div>
                </div></td>
            </tr>
            <tr>
              <td>Workflow Automation<br/> – Binder Preparation and Reporting</td>
              <td>
                <div class="circle-container">
                  <div class="circle">
                    <div class="checkmark"></div>
                  </div>
                </div>
              </td>
              <td><div class="circle-container">
                  <div class="circless">
                    <div class="checkmark-Third"></div>
                  </div>
                </div></td>

              <td>
              <div class="circle-container">
                  <div class="circless">
                    <div class="checkmark-Third"></div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Auto-extraction of data points Text, Images, Tables,
                Handwritten, Visual elements <br/>– Checkboxes, Currencies, Metrics,
                Volumes
              </td>
              <td>
                <div class="circle-container">
                  <div class="circle">
                    <div class="checkmark"></div>
                  </div>
                </div>
                Focus on critical data blocks extraction and preprocessing for
                LLMs
              </td>
              <td>
                {" "}
                <div class="circle-container">
                  <div class="circle">
                    <div class="checkmark"></div>
                  </div>
                </div>
              </td>
              <td>
                <div className="circle-container">
                  <div className="circles">
                    <div className="checkmark-secondary"></div>
                  </div>
                </div>
                Inconsistent
              </td>
            </tr>
            <tr>
              <td>
                Conversational Query with Knowledge Augmentation for Queries and
                Data representation
              </td>
              <td>
                <div class="circle-container">
                  <div class="circle">
                    <div class="checkmark"></div>
                  </div>
                </div>
                Gen AI Supported
              </td>
              <td>
              <div class="circle-container">
                  <div class="circless">
                    <div class="checkmark-Third"></div>
                  </div>
                </div>
              </td>
              <td>
                <div class="circle-container">
                  <div class="circle">
                    <div class="checkmark"></div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Export to CSV / JSON</td>
              <td>
                <div class="circle-container">
                  <div class="circle">
                    <div class="checkmark"></div>
                  </div>
                </div>
              </td>
              <td>
              <div class="circle-container">
                  <div class="circless">
                    <div class="checkmark-Third"></div>
                  </div>
                </div>
              </td>
              <td>
                <div class="circle-container">
                  <div class="circle">
                    <div class="checkmark"></div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Auto-Learning Engine</td>
              <td>
                <div class="circle-container">
                  <div class="circle">
                    <div class="checkmark"></div>
                  </div>
                </div>
              </td>
              <td><div class="circle-container">
                  <div class="circless">
                    <div class="checkmark-Third"></div>
                  </div>
                </div></td>
              <td>
                <div className="circle-container">
                  <div className="circles">
                    <div className="checkmark-secondary"></div>
                  </div>
                </div>
                Needs training for domain specific data formats and datapoints
              </td>
            </tr>
            <tr>
              <td>API available</td>
              <td>
                <div class="circle-container">
                  <div class="circle">
                    <div class="checkmark"></div>
                  </div>
                </div>
              </td>
              <td><div class="circle-container">
                  <div class="circless">
                    <div class="checkmark-Third"></div>
                  </div>
                </div></td>
              <td>
                <div class="circle-container">
                  <div class="circle">
                    <div class="checkmark"></div>
                  </div>
                </div>{" "}
              </td>
            </tr>
            <tr>
              <td>Hyperlocal Gen AI stack for On-Prem deployment</td>
              <td>
                <div class="circle-container">
                  <div class="circle">
                    <div class="checkmark"></div>
                  </div>
                </div>
                Enterprise Version
              </td>
              <td><div class="circle-container">
                  <div class="circless">
                    <div class="checkmark-Third"></div>
                  </div>
                </div></td>
              <td>
              <div class="circle-container">
                  <div class="circless">
                    <div class="checkmark-Third"></div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default DynamicTable;
