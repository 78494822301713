/* eslint-disable react/jsx-no-undef */
import React, { useRef } from "react";
import styles from "./Card.module.css";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import ShieldIconImage from "../images/shield-icon.svg";

import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import qsense from "components/cta/qsense.js";

import Footer from "components/footers/FiveColumnWithInputForm.js";
import DesignIllustration from "../images/Extraction.png";
import Classification from "../images/Classification.png";
import Querying from "../images/Querying.png";
import Reporting from "../images/Reporting.png";
import tax from "../images/tax.png";
import risk from "../images/risk.png";
import finance from "../images/finance.png";
import kyc from "../images/kyc.png";
import backoffice1 from "../images/backoffice1.png";
import sop1 from "../images/sop1.png";
import TextTransition, { presets } from "react-text-transition";
import MyComponent from "./iframe";

import EquityResearch from "../images/Equity Research.png";
import KYCverification from "../images/KYC verification.png";
import Risk from "../images/Risk.png";
import operations from "../images/operations.png";
import Customer from "../images/Customer.png";

import querysense from "../images/querysense.jpg";
import Accelarates from "../images/Accelarates.png";
import "./QsenseLandingPage.css";
import TwoColumnWithVideo from "components/hero/TwoColumnWithVideo.js";

import Header from "../components/headers/light";
import TwoColumnPrimaryBackground from "components/hero/TwoColumnPrimaryBackground";
import DynamicTable from "./DynamicTable";
export default ({ title, description, imageUrl }) => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  const Container = tw(ContainerBase)`bg-gray-300 bg-[#ffffff] -mx-8`;

  const buttonStyle = {
    backgroundColor: "#359fff",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "5px",
    margin: "0 10px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  };

  buttonStyle.hover = {
    backgroundColor: "#2580d8",
  };

  const iframeRefs = useRef();

  const scrollToIframe = () => {
    if (iframeRefs.current) {
      iframeRefs.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const vid = useRef();

  const video = () => {
    if (vid.current) {
      vid.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const logoUrl =
    "https://images.template.net/111397/free-instagram-verified-icon-clipart-5ktr9.jpg";

  const points = [
    {
      title: "Faster decisions",
      description: "2x faster Turn Around Time and Higher accuracy.",
    },
    {
      title: "Higher data quality",
      description: "Accelerates data exploration .",
    },
    {
      title: "Accelerate processes",
      description: "Up to 60% reduction in manual efforts.",
    },
    {
      title: "Cost savings",
      description:
        "Provided more than 18% returns on impact investment decisions.",
    },
  ];
  const pairs = [];
  for (let i = 0; i < points.length; i += 2) {
    const point1 = points[i];
    const point2 = points[i + 1];
    pairs.push([point1, point2]);
  }

  const TEXTS = [
    "Back Office Finance",
    "Taxation & Accounting",
    "Risk & Compliance",
  ];

  React.useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 2000);
    return () => clearTimeout(intervalId);
  }, []);

  const [index, setIndex] = React.useState(0);
  React.useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 2000);
    return () => clearTimeout(intervalId);
  }, []);

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": "qsense",
    "alternateName": ["qsense.ai", "qsense", "qsenseai", "querysense"],
    "url": "https://www.qsense.ai/",
  };
  
  return (
    <>
      <Header />
      <div>
      {/* Your component's JSX content */}
      
      {/* Add JSON-LD structured data */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </div>
      <AnimationRevealPage>
        <Hero
          heading={
            <>
              <div style={{ fontSize: "0.7em", color: "#243E63" }}>
                AI Copilot for data discovery in
              </div>
              <div>
                <div style={{ color: "#1E91FF" }}>
                  <TextTransition
                    style={{
                      fontSize: "0.7em",
                      "@media screen and (max-width: 768px)": {
                        fontSize: "0.6em",
                        color: "#1E91FF",
                      },
                    }}
                    springConfig={presets.wobbly}
                  >
                    {TEXTS[index % TEXTS.length]}
                  </TextTransition>
                </div>
              </div>{" "}
            </>
          }
          description={
            <div>
              Unlock the power of data effortlessly with our Qsense. No more
              struggling with complex queries!
            </div>
          }
          imageSrc={querysense}
          imageCss={imageCss}
          imageDecoratorBlob={true}
          primaryButtonText="Demo"
          primaryButtonOnClick={scrollToIframe}
          // watchVideoButtonText="Watch Video"
          watchVideoButtonOnClick={video}
        />
        <div ref={iframeRefs}></div>
        <br />

        <div style={{ paddingBottom: "50px", paddingTop: "2.5rem" }}>
          <h1 className="heading">Click on a sample below for a preview</h1>

          <MyComponent />
        </div>

        {/* <Container > */}
        <div tw="container mx-auto" className="benefits-container">
          <div className="benefit-container">
            <div className="benefit">
              <div className="benefit-block">
                <strong>Extraction</strong>
              </div>
              <img
                src={DesignIllustration}
                alt="High-quality transcription"
                className="benefit-image"
              />
              <p>
                Quickly extract data from any type of document, including PDFs,
                scanned copies, and images
              </p>
            </div>

            <div className="benefit">
              <div className="benefit-block">
                <strong>Classification</strong>
              </div>
              <img
                src={Classification}
                alt="Flexible deployment"
                className="benefit-image"
              />
              <p>
                Analyze and auto-classify extracted data with field mapping and
                data labeling
              </p>
            </div>

            <div className="benefit">
              <div className="benefit-block">
                <strong>Querying</strong>
              </div>
              <img
                src={Querying}
                alt="Customizable models"
                className="benefit-image"
              />
              <p>
                Conversational querying for faster data discovery, make informed
                decisions with business insights
              </p>
            </div>

            <div className="benefit">
              <div className="benefit-block">
                <strong>Reporting</strong>
              </div>
              <img
                src={Reporting}
                alt="Customizable models"
                className="benefit-image"
              />
              <p>
                Get detailed reports and data analysis from various aspects -
                trends/patterns, anomalies, 360-degree view
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
        <></>

        <div className="heading">
          Accelerate AI-driven data discovery for 360-degree Insights
        </div>
        <MainFeature2
          // subheading={<Subheading></Subheading>}
          // heading={<>Accelerate AI-driven data discovery<br/>for 360-degree Insights</>}
          // primaryButtonUrl="https://order.now.com"
          // imageInsideDiv={false}
          // imageSrc={Accelarates}
          // imageCss={ Object.assign(tw`bg-cover mt-[200px] ml-[30px]`, imageCss)}
          // imageContainerCss={tw`md:w-1/2 h-auto`}
          // imageDecoratorBlob={true}
          textOnLeft={true}
        />
        <DynamicTable />
        <br />
        <TwoColumnPrimaryBackground/>

        <br />


        <div className="card">
          <img src={imageUrl} alt={title} className="card-image" />
          <div className="card-content">
            <h3 className="card-title">{title}</h3>
            <p className="card-description">{description}</p>
          </div>
        </div>

          {/* <br/><br/><br/> */}

          <div tw="container mx-auto" className="benefits-container">
            <div className="benefits">
              <div className="benefits-block">
                <strong>Back-office Finance</strong>
              </div>
              <div className="benefits-description">
                Qsense streamlines invoice handling, enabling the back office
                team to effortlessly process invoices, manage vendor contracts,
                and handle accounts receivables/payables efficiently.
              </div>
              <img tw='object-cover' src={backoffice1} alt="High-quality transcription" />
            </div>

            <div className="benefits">
              <div className="benefits-block">
                <strong>Tax statements analysis</strong>
              </div>
              <div className="benefits-description">
                Qsense assists CPAs and CAs in analyzing tax statements, helping
                to identify patterns and anomalies, and detecting potential
                errors and discrepancies.
              </div>
              <img tw='object-cover' src={tax} alt="High-quality transcription" />
            </div>

            <div className="benefits">
              <div className="benefits-block">
                <strong>KYC verification </strong>
              </div>
              <div className="benefits-description">
                Qsense simplifies Know Your Customer (KYC) and onboarding
                processes in lending. Teams can use it to verify customer
                identities and streamline the lending application process,
                reducing delays.
              </div>
              <img tw='object-cover' src={kyc} alt="High-quality transcription" />
            </div>

            <div className="benefits">
              <div className="benefits-block">
                <strong>Risk and Compliance Audit </strong>
              </div>
              <div className="benefits-description">
                Qsense enhances risk and compliance teams' capabilities. It
                allows them to analyze vast datasets for anomalies, ensuring
                adherence to regulations and minimizing compliance risks.
              </div>
              <img tw='object-cover' src={risk} alt="High-quality transcription" />
            </div>

            <div className="benefits">
              <div className="benefits-block">
                <strong>Issue Resolution </strong>
              </div>
              <div className="benefits-description">
                Qsense assists operations teams in resolving issues promptly.
                Team members can ask questions to access relevant data from SOPs
                & historical records, track processes, and diagnose problems,
                leading to quicker & effective issue resolution.
              </div>
              <img tw='object-cover' src={sop1} alt="High-quality transcription" />
            </div>

            <div className="benefits">
              <div className="benefits-block">
                <strong>Fraud Detection</strong>
              </div>
              <div className="benefits-description">
                Qsense analyzes vast amounts of data to identify patterns and
                anomalies that may indicate fraudulent activities in insurance
                claims.
              </div>
              <img tw='object-cover' src={finance} alt="High-quality transcription" />
            </div>
          </div>
          <br />
          <br />
          <br />
        {/* <div      ref={vid}>
    <DownloadApp
    ref={vid}
      text={<>2x faster Turn Around Time and Higher accuracy.</>}

    />
    </div> */}

        <Footer />
      </AnimationRevealPage>
    </>
  );
};
