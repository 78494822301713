import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/stats-illustration.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import styles from "../../demos/Card.module.css"

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-10`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-xl font-medium leading-relaxed text-secondary-100  pl-20`;

const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
const Key = tw.div`font-medium text-primary-700`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);

export default ({
  // subheading = "Our Track Record",
  // heading = (
  //   <>
  //     We have been doing this <wbr /> since <span tw="text-primary-500">1999.</span>
  //   </>
  // ),

  description = <div>Querysense is powered by the advanced Natural Language Querying (NLQ), you can chat or talk to your data naturally. Our NLP engine understands your questions and uses machine learning to give you quick, meaningful answers.</div>,
  // primaryButtonText = "Learn More",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "",
      value: ""
    },
    {
      key: "",
      value: ""
    },
    {
      key: "",
      value: ""
    }
  ];

  if (!statistics) statistics = defaultStatistics;

  const points = [
    {
      title: 'Faster decisions',
      description: '2x faster Turn Around Time and Higher accuracy.',
    },
    {
      title: 'Cost savings',
      description: 'Provided more than 18% returns on impact investment decisions.',
    },
   
    {
      title: 'Accelerate processes',
      description: 'Up to 60% reduction in manual efforts.',
    },
    {
      title: 'Higher data quality',
      description: 'Accelerates data exploration .',
    },
   
  ];
  const pairs = [];
  for (let i = 0; i < points.length; i += 2) {
    const point1 = points[i];
    const point2 = points[i + 1];
    pairs.push([point1, point2]);
  }
  const logoUrl = 'https://images.template.net/111397/free-instagram-verified-icon-clipart-5ktr9.jpg';

  return (
    <Container>
      <TwoColumn  css={ tw`md:items-center pt-10` }>
        <ImageColumn >
          {/* {imageInsideDiv ? <Image imageSrc={imageSrc} css={imageCss} /> : <img src={imageSrc} css={imageCss} alt="" />}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />} */}
           <div className={styles.container}>
      {points.map((point, index) => (
        <div key={index} className={styles.card}>
          <div className={styles.content}>
            <img src={logoUrl} alt="Logo" className={styles.logo} />
            <div className={styles.text}>
              <h3 className={styles.title}>{point.title}</h3>
              <p>{point.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {/* {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading> */}
            <Description>{description}</Description>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>
            {/* <PrimaryButton as="a" href={primaryButtonUrl}> */}
              {/* {primaryButtonText} */}
            {/* </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
