import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro"; 
import GitHubButton from "react-github-btn";

import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import Footer from "../../querysense-landing-page/src/components/footers/FiveColumnWithInputForm"

import logo from "images/logo.svg";
import logo2 from "../src/images/RecoSenseLogo.png";
import Header from "../../querysense-landing-page/src/components/headers/light.js";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline`;
const HeroRow = tw(Row)`max-w-screen-lg  flex-col justify-between pb-20 lg:pb-24 mx-auto`; 

const Heading = tw(HeadingBase)` text-primary-900 leading-snug`;
const Description = tw(DescriptionBase)`mt-4 lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  window.gtag("js", new Date());
  window.gtag("config", "UA-45799926-9");

  const downloadUrl = "/treact-ui.zip"
  // React.useEffect(() => {
  //   var iframe = document.createElement("iframe");
  //   iframe.style.display = "none";
  //   iframe.src = downloadUrl
  //   document.body.appendChild(iframe);
  // }, [])

  return (
    <>
    <div >
    <Header /></div>
    <AnimationRevealPage disabled>
      <Container tw="-mx-8 -mt-8 pt-8 px-8 flex justify-between">
        <Content2Xl>
          {/* <NavRow> */}
          {/* <LogoLink href="/">
            <img style={{ height: "78px", width: "157px" }} src={logo2} alt="" />
            Qsense
          </LogoLink> */}
          {/* <div tw="flex flex-col lg:flex-row items-center">
      <NavLink target="_blank" href="https://owaiskhan.me/post/free-tailwindcss-react-ui-kit">
        License & Usage
      </NavLink>
      <NavLink target="_blank" href="https://twitter.com/owaiswiz">
        Twitter
      </NavLink>
      <NavLink target="_blank" href="https://owaiskhan.me">
        Who Am I ?
      </NavLink>
      <PrimaryNavLink target="_blank" href="https://gum.co/QaruQ">
        Download Now
      </PrimaryNavLink>
    </div> */}
          {/* </NavRow> */}
          <HeroRow>
            <Heading>Terms Of Use</Heading>
            {/* <Description tw="mt-12">This Privacy Policy (“Privacy Policy”) explains how information about you or your clients (“Clients,” together “you”, or “yours”) is collected, used, and disclosed by Recosenselabs, Inc. and its affiliates (collectively, “Recosense,” “we,” “us” or “our”) when you access or use our websites (including, without limitation, https://qsense.ai and any other websites owned or controlled by us that link to this Privacy Policy) (each, a “Website” and collectively, the “Websites”) and any associated features, functionalities, user interfaces, downloads, applications, digital properties and other online services (collectively, the “Services”) Services. By accessing or using our Services, you agree to our collection, use, and disclosure of your information as outlined in this Privacy Policy.  */}
            {/* <NavLink href={downloadUrl} download>click this link</NavLink>. */}
            {/* </Description> */}
            <div tw="mt-12 ">
              Please carefully review these Terms of Use (these “Terms”), as these Terms are a binding contract between you or the entity you represent (collectively, “you”) and Recosenselabs Technologies, Inc. and its affiliates (collectively, “Recosenselabs,” “we,” “us” or “our”) and apply to your access to and use of our websites (including, without limitation, https://www.qsense.ai and any other websites owned or controlled by us that link to these Terms) (each, a “Website” and collectively, the “Websites”) and any associated features, functionalities, user interfaces, downloads, applications, digital properties and other online services (collectively with the Websites and all Recosenselabs Property (as defined below), the “Services”) which are owned or controlled by us, and any content made available through the Services (“Content”).<br /><br />
              In addition to these Terms, our Privacy Policy and, in some instances, separate guidelines, rules, or terms that are posted on the Services or made available to you (individually and collectively, “Additional Terms”), apply to your access to and use of the Services, in whole or in part, and/or to Content made available through the Services, in whole or in part. In the event of a direct conflict between these Terms and any Additional Terms, the Additional Terms shall govern, but solely with respect to the Service(s) at issue.<br /><br />
              <b>BY CLICKING “I ACCEPT”, OR OTHERWISE ACCESSING OR USING ANY SERVICES, YOU ACKNOWLEDGE YOU HAVE READ AND UNDERSTOOD THE TERMS AND CONDITIONS OF THESE TERMS AND AGREE TO BE BOUND BY THEM. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THESE TERMS, INCLUDING THE MANDATORY ARBITRATION PROVISION AND CLASS ACTION WAIVER IN SECTION 14, DO NOT ACCESS OR OTHERWISE USE THE SERVICES OR ANY CONTENT MADE AVAILABLE THROUGH THE SERVICES.</b><br /><br />
              In order to use the Services or access any Content, you must be 18 years or older (or the age of legal majority where you live) (the “Eligible Age”).  If you are under the Eligible Age, you are prohibited from accessing or using the Services or any Content made available through the Services.<br /><br />
              <b>1. Changes to these Terms.</b><br /><br />
              We may make changes to these Terms from time to time. If we make changes, we will post the amended Terms to the Services and update the “Last Updated” date above. We may also attempt to notify you by other means, including by displaying a prominent notice within the Services, as applicable and appropriate under the circumstances. Unless we say otherwise in our notice, the amended Terms will be effective immediately upon posting and your continued access to or use of the Services or Content after the changes have been posted will constitute your acceptance of the changes. If you do not agree to the amended Terms, you must stop accessing or using the Services and any Content made available through the Services.<br /><br />
              If you have any questions about these Terms or the Services, please address them to support@qsense.ai.<br /><br />
              <b>2. Eligibility</b><br /><br />
              You must be at least the Eligible Age to access or use the Services or Content. If you are accessing or using the Services or Content on behalf of another person or entity, you represent that you are authorized to accept these Terms on that person’s or entity’s behalf and that the person or entity agrees to be responsible to us if you or the other person or entity violates these Terms.<br /><br />
              <b>3. Accounts</b><br /><br />
              In order to access some or all of the Services, you may need to create an account with us. If you create an account, you agree to provide accurate account information and promptly update this information if it changes. You are solely responsible for all activities that occur under your account, password, and username – whether or not you authorized the activity – and you agree not to sell, transfer, or assign your account or any account rights to any third party. You also agree to maintain the security of your account credentials, and promptly notify us if you discover or suspect that someone has accessed your account or any of your account credentials without your permission. If you register an account on behalf of a company, the terms “you” and “your,” as used throughout these Terms, apply to both you and that company, and you agree that you are authorized to grant all permissions and licenses provided in these Terms (and any Additional Terms) and to bind that company to these Terms (and any Additional Terms).<br /><br />
              <b>4. The Services Do Not Constitute Tax Advice</b><br /><br />
              Use of the Services by you does not form a tax preparer-client relationship between you and Recosenselabs. Information posted or made available on or through the Services, including, without limitation, any responses to tax questions posted on the Services, information posted publicly on the Services, or information sent in an unsolicited message to a user or visitor of the Services is not intended as tax advice, is not confidential, and does not create a tax preparer-client relationship.<br /><br />
              You acknowledge and agree that Recosenselabs is not liable to you or any third party for any loss due to or arising out of any financial or personal decision you take related to your use of the Services, any loss due to or arising out of inaccurate information that we receive from you or any third party related to your use of the Services, or any delay your filing of any tax returns.<br /><br />
              <b>5. Communications</b><br /><br />
              When you communicate with us electronically, such as via email or through the Services, you consent to receive electronic communications from Recosenselabs in accordance with applicable law and any Additional Terms, including text messages, calls, and push notifications to the cellular telephone number you provide to us. These communications may include notices about products or services we provide (e.g., payment authorizations and other transactional information) and are part of your relationship with us. You agree that any notices, agreements, disclosures or other communications that we send to you electronically satisfy any legal communication requirements, including, but not limited to, that such communications be in writing. You should maintain copies of electronic communications from us by printing a paper copy or saving an electronic copy.<br /><br />
              <b>6. Privacy</b><br /><br />
              By downloading, accessing or using the Services or Content, you acknowledge and accept our Privacy Policy, which discloses how we collect, use, and share personal information that you provide to us through the Services.<br /><br />
              <b>7. Use of Recosenselabs Property</b><br /><br />
              7.1.Recosenselabs Property. The Services contain a variety of: (i) materials and other items relating to Recosenselabs and its products and services (and similar items from our licensors and other third parties), including all Content, layout, information, blog posts, articles, reports, demos, text, data, files, images, scripts, designs, graphics, button icons, instructions, illustrations, photographs, audio clips, music, sounds, pictures, videos, advertising copy, URLs, technology, software, interactive features, the “look and feel” of the Services, and the compilation, assembly, and arrangement of the materials of the Services and any and all copyrightable material (including source and object code); (ii) trademarks, logos, trade names, trade dress, service marks, and trade identities of various parties, including those of Recosenselabs (collectively, “Trademarks”); and (iii) other forms of intellectual property (all of the foregoing, collectively, “Recosenselabs Property”).<br />
              7.2.Ownership. The Services (including past, present, and future versions) are owned or controlled by Recosenselabs and our licensors and certain other third parties.  All right, title, and interest in and to the Recosenselabs Property is the property of Recosenselabs or our licensors or certain other third parties, and is protected by U.S. and/or international copyright, trademark, trade dress, patent and/or other intellectual property and unfair competition rights and laws. Except as explicitly stated in these Terms or any Additional Terms, Recosenselabs, our licensors, and such other third parties reserve all rights in and to the Services.<br />
              7.3.Limited License. Recosenselabs Property is licensed, not sold, to you by Recosenselabs. Subject to your strict compliance with these Terms and any Additional Terms, Recosenselabs hereby grants to you a limited, non-exclusive, revocable, non-assignable, non-sublicensable, personal, non-transferable license to perform and/or display the Recosenselabs Property (excluding source and object code in raw form or otherwise, other than as made available to access and use to enable display and functionality) on supported devices for your personal, non-commercial use or internal business purposes only solely through the Services or as otherwise expressly permitted by Recosenselabs, and to download such Recosenselabs Property (temporary storage only) solely to enable such performance and display (the “Limited License”). The Limited License shall remain in effect until and unless terminated by you or Recosenselabs.<br />
              7.4.Limitations. Unless specifically indicated otherwise, you may not sell, rent, lease, distribute, broadcast, publicly perform, publicly display, sublicense, or assign any rights to the Recosenselabs Property or any portion of it to any third party, and you may not remove or modify any proprietary notices or labels on the Recosenselabs Property or otherwise make any derivative uses of the Services. In addition, you may not use any data mining, robots or similar data gathering or extraction methods or attempt to bypass, modify, defeat, or otherwise circumvent any digital rights management system or other content protection or features used as part of the Services. Any use of the Services other than as specifically authorized in these Terms (and any Additional Terms), without our prior written permission, is strictly prohibited and will automatically terminate the Limited License.<br /><br />
              <b>8. Prohibited Conduct</b><br /><br />
              You are solely responsible for your conduct while accessing or using the Services.  You agree that you will not:
              Use the Services in any manner that could interfere with, disrupt, negatively affect or inhibit other users or visitors of the Services from fully enjoying the Services or that could damage, disable, overburden, or impair the functioning of the Services in any manner;
              Reverse engineer any aspect of the Services or do anything that might discover source code or bypass or circumvent measures employed to prevent or limit access to any part of the Services;
              Attempt to circumvent any content-filtering techniques we employ or attempt to access any feature or area of the Services that you are not authorized to access;
              Copy, download, redistribute, reproduce, “rip”, record, transfer, display to the public, broadcast, or make available to the public any part of the Services, or otherwise make any use of the Services which is not expressly permitted under these Terms, any Additional Terms, or applicable law, or which otherwise infringes, misappropriates or otherwise violates the intellectual property rights (such as copyright) in the Services, or any part thereof;
              Develop or use any third-party applications that interact with the Services without our prior written consent, including any scripts designed to scrape or extract data from the Services; and/or
              Bypass or ignore instructions contained in our robots.txt file, or equivalent restrictive technologies that control automated access to portions of the Services.<br /><br />
              <b>9. Unsolicited Submissions</b><br /><br />
              Recosenselabs does not accept or consider unsolicited feedback, information, drawings, comments, suggestions, ideas, proposals, or concepts (collectively, “Unsolicited Submissions”). Recosenselabs requests that you do not submit any Unsolicited Submissions. However, by submitting Unsolicited Submissions to us on or through the Services, you hereby grant to Recosenselabs a perpetual, irrevocable, nonexclusive, royalty-free, worldwide, fully-paid license to use, copy, record, disclose, sell, resell, sublicense, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, publicly perform, broadcast, translate, publicly display, and otherwise use and exploit all or any portion of your Unsolicited Submissions for the purposes of: (i) enabling us to operate and provide the Services as authorized by these Terms and any Additional Terms; and (ii) using in connection with developing and/or improving the Services, in each case, in any format and channels now known or later developed and with any technology or devices now known or later developed, and to advertise, market, and promote the same, without compensation, credit, or notice to you.  Except as prohibited by applicable law, you hereby waive, and you agree to waive, any moral rights (including attribution and integrity) that you may have in any Unsolicited Submissions, even if it is altered or changed in a manner not agreeable to you. To the extent not waivable, you irrevocably agree not to exercise such rights (if any) in a manner that interferes with any exercise of the granted rights.<br /><br />
              <b>10. Third Parties</b><br /><br />
              Certain Services may display, include, or make available content, data, information, applications, plugins, or materials from third parties (“Third-Party Materials”) or provide links to certain third-party websites. Any activities in which you engage in connection with any Third-Party Materials are subject to the privacy and other policies, terms and conditions of use and/or sale, and rules issued by the owner or operator of the Third-Party Materials.  By using the Services, you acknowledge and agree that Recosenselabs is not responsible for examining or evaluating the content, accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect of such Third-Party Materials or third-party websites. The Recosenselabs Parties do not warrant or endorse and do not assume and will not have any liability or responsibility to you or any other person for any third-party services, Third-Party Materials or third-party websites, or for any other materials, products, or services of third parties. Third-Party Materials and links to third-party websites are provided solely as a convenience to you. You agree that you will not use any Third-Party Materials in a manner that would infringe or violate the rights of any other party, and that Recosenselabs is not in any way responsible for any such use by you.<br /><br />
              <b>11. Indemnification</b><br /><br />
              To the fullest extent permitted by applicable law, you will indemnify, defend (at Recosenselabs’s option), and hold harmless Recosenselabs and its parents, subsidiaries and other affiliates, and each of their respective officers, directors, employees, agents, third-party providers, licensors, partners and successors and assigns (individually and collectively, the “Recosenselabs Parties”) from and against any losses, liabilities, damages, penalties, expenses and costs (including reasonable attorneys’ fees) (collectively, “Damages”) incurred in connection with any third-party claim, demand, cause of action, suit, investigation, arbitration, or other proceeding, including any governmental inquiries, investigations and proceedings (collectively, “Claims”) arising out of or related to your actual or alleged violation of these Terms or any Additional Terms.  You agree that the Recosenselabs Parties may take control of the defense or settlement of any Claims and you agree to cooperate with the Recosenselabs Parties in defending such Claims, at Recosenselabs’s request.<br /><br />
              If you are a California resident, you waive California Civil Code Section 1542, which says: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor.” If you are a resident of another jurisdiction, you waive any comparable statute or doctrine.<br /><br />
              <b>12. Disclaimers</b><br /><br />
              YOUR ACCESS TO AND USE OF THE SERVICES AND CONTENT IS AT YOUR SOLE RISK. THE SERVICES AND Recosenselabs PROPERTY ARE PROVIDED “AS IS” AND “AS AVAILABLE”, AND WE DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT OR MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, CUSTOM, TRADE, SYSTEM INTEGRATION, AND FREEDOM FROM COMPUTER VIRUS. RECOSENSELABS DOES NOT REPRESENT OR WARRANT THAT THE SERVICES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. WHILE RECOSENSELABS ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE SERVICES SAFE, WE CANNOT AND DO NOT REPRESENT OR WARRANT THAT THE SERVICES OR ANY SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.<br /><br />
              UNLESS EXPRESSLY STATED IN WRITING IN A SEPARATE AGREEMENT BETWEEN Recosenselabs AND YOU, Recosenselabs DOES NOT PROVIDE LEGAL, FINANCIAL, ACCOUNTING OR OTHER PROFESSIONAL SERVICES OR ADVICE. THE SERVICES IN NO WAY CONSTITUTE AND ARE NOT A REPLACEMENT FOR PERSONAL, PROFESSIONAL, TAX, OR BUSINESS ADVICE TAILORED TO YOUR SPECIFIC NEEDS.
              WE DO NOT CONTROL, ENDORSE OR TAKE RESPONSIBILITY FOR ANY THIRD-PARTY MATERIALS OR OTHER THIRD-PARTY CONTENT AVAILABLE ON OR LINKED TO BY THE SERVICES. REFERENCE TO ANY PRODUCTS, SERVICES, PROCESSES OR OTHER INFORMATION BY TRADE NAME, TRADEMARK, MANUFACTURER, SUPPLIER, VENDOR OR OTHERWISE DOES NOT CONSTITUTE OR IMPLY ENDORSEMENT, SPONSORSHIP OR RECOMMENDATION THEREOF, OR ANY AFFILIATION THEREWITH, BY US.<br /><br />
              <b>13. Limitation of Liability</b><br /><br />
              Except for (i) liability caused by your or Recosenselabs’s gross negligence, willful misconduct or fraud, or (ii) your breach of Section 8 (Prohibited Conduct), and without limiting your obligations under  Section 11 (Indemnification), to the fullest extent permitted by applicable law, neither you nor the Recosenselabs Parties will be liable to the other under any theory of liability—whether based in contract, tort, negligence, strict liability, warranty, or otherwise, and whether you or the Recosenselabs Parties, as applicable, were advised of or should have known of the possibility of such losses or damages—for any indirect, consequential, exemplary, incidental, punitive or special damages or lost profits or loss of data.<br /><br />
              Except as may be provided in any Additional Terms, to the fullest extent permitted by applicable law, the aggregate liability of the Recosenselabs Parties for any claim arising out of or relating to these Terms, any Additional Terms or the Services, regardless of the form of the action, is limited to the greater of (a) $100 US Dollars or (b) the amount you have paid to us for use of the Services in the twelve (12) months preceding the date on which the claim arose.<br /><br />
              <b>14. Dispute Resolution; Binding Arbitration</b><br /><br />
              Please read the following section carefully because it requires you to arbitrate certain disputes and claims with Recosenselabs and limits the manner in which you can seek relief from us.<br /><br />
              Except for small claims disputes in which you or Recosenselabs seek to bring an individual action in small claims court located in the county in which you reside (or are headquartered if accepting these Terms on behalf of an entity) or disputes in which you or Recosenselabs seek injunctive or other equitable relief for the alleged unlawful use of intellectual property, you and Recosenselabs waive your rights to a jury trial and to have any dispute arising out of or related to these Terms, any Additional Terms or the Services resolved in court.<br /><br />
              Instead, all disputes arising out of or relating to these Terms, any Additional Terms, or the Services will be resolved through confidential binding arbitration held in Travis County, Texas in accordance with the Streamlined Arbitration Rules and Procedures (the “Rules”) of the Judicial Arbitration and Mediation Services (“JAMS”), which are available on the JAMS website and hereby incorporated by reference. You either acknowledge and agree that you have read and understand the rules of JAMS or waive your opportunity to read the rules of JAMS and any claim that the rules of JAMS are unfair or should not apply for any reason.<br /><br />
              You and Recosenselabs agree that any dispute between you and Recosenselabs arising out of or related to these Terms, any Additional Terms, or the Services is personal to you and Recosenselabs, will be resolved solely through individual arbitration, and will not be brought as a class arbitration, class action or any other type of representative proceeding.
              You and Recosenselabs agree that these Terms and any Additional Terms affect interstate commerce and that the enforceability of this Section 14 will be substantively and procedurally governed by the Federal Arbitration Act, 9 U.S.C. § 1, et seq. (the “FAA”), to the maximum extent permitted by applicable law.  As limited by the FAA, these Terms, any Additional Terms, and the JAMS Rules, the arbitrator will have exclusive authority to make all procedural and substantive decisions regarding any dispute and to grant any remedy that would otherwise be available in court; provided, however, that the arbitrator does not have the authority to conduct a class arbitration or a representative action, which is prohibited by these Terms. The arbitrator may only conduct an individual arbitration and may not consolidate more than one individual’s claims, preside over any type of class or representative proceeding or preside over any proceeding involving more than one individual.<br /><br />
              If you are accessing the Services on behalf of any entity, each of you and Recosenselabs shall bear its own attorneys’ fees and its own costs and expenses (including filing fees) and shall also bear one half of the total arbitrator’s, or arbitrators’, as the case may be, and other administrative fees of arbitration. The arbitrator shall be entitled to award the foregoing arbitration and administrative fees and expenses as damages in their discretion.  The judgment on the award rendered by the arbitrator shall be final, and may be filed with and enforced as a final judgment by any court of competent jurisdiction.<br /><br />
              Any claim arising out of or related to these Terms, any Additional Terms, or the Services must be filed within one (1) year after such claim arose; otherwise, the claim is permanently barred, which means that you or Recosenselabs, as applicable, will not have the right to assert the claim.  <br /><br />
              <b>15. Governing Law and Venue</b><br /><br />
              These Terms, any Additional Terms, and your access to and use of the Services will be governed by and construed and enforced in accordance with the laws of the State of Texas, without regard to conflict of law rules or principles that would cause the application of the laws of any other jurisdiction. Any dispute between you and Recosenselabs that is not subject to arbitration or cannot be heard in small claims court will be resolved in the state or federal courts of the State of Texas and the United States, respectively, sitting in Travis County, Texas.<br /><br />
              <b>16. Termination</b><br /><br />
              We reserve the right, without notice and in our sole discretion, to terminate your right to access or use the Services or Content available through the Services, in whole or in part. We are not responsible for any loss or harm related to your inability to access or use the Services or any Content available through the Services, in whole or in part.<br /><br />
              <b>17. Severability</b><br /><br />
              If any provision or part of a provision of these Terms or any Additional Terms is unlawful, void or unenforceable, that provision or part of the provision is deemed severable and does not affect the validity and enforceability of any remaining provisions.
              <b>18. Export Controls</b><br /><br />
              Software related to or made available by the Services may be subject to export controls of the U.S.A. or similar laws applicable in other jurisdictions. You warrant that you are (1) not located in any country to which the United States has embargoed goods or has otherwise applied any economic sanctions; and (2) not a denied party as specified in any applicable export or re-export laws or regulations or similar laws applicable in other jurisdictions.<br /><br />
              You are responsible for complying with all trade regulations and laws both foreign and domestic. Except as authorized by law, you agree and warrant not to export or re-export the software to any county, or to any person, entity, or end-user subject to U.S. export controls.<br /><br />
              <b>19. Miscellaneous</b><br /><br />
              These Terms and any Additional Terms constitute the entire agreement between you and Recosenselabs relating to your download of, access to and/or use of the Services or any Content available through the Services. The failure of Recosenselabs to exercise or enforce any right or provision of these Terms or any Additional Terms will not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. Except as otherwise provided herein, these Terms are intended solely for the benefit of you and Recosenselabs and are not intended to confer third-party beneficiary rights upon any other person or entity.<br /><br />


              <span tw="font-bold"></span>
              {/* <span tw="font-bold">We collect several different types of information for various purposes to provide and improve our service to you.</span>. */}
            </div>
            {/* <div tw="mt-12 text-center">
    Personal Data: While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:

Email address
First name and last name
Phone number
Address, State, Province, ZIP/Postal code, City
Cookies and Usage Data
Usage Data: We may also collect information on how the Site is accessed and used ("Usage Data").
    
    </div> */}
          </HeroRow>
        </Content2Xl>
      </Container>
    </AnimationRevealPage><Footer /></>
  );
};
