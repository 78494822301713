import useWindowResize from "components/misc/useWindowSize";
import React, { useState, useEffect, useRef } from "react";

const MyComponent = () => {
  const [showLoader, setShowLoader] = useState(true);
  const iframeRef = useRef();

  const scrollToIframe = () => {
    // if (iframeRef.current) {
    //   iframeRef.current.scrollIntoView({ behavior: 'smooth' });
    // }
  };

  const handleIframeLoad = () => {
    setShowLoader(false);
    scrollToIframe();
  };

  const handleIframeError = (error) => {
    console.error("Iframe error:", error);
    setShowLoader(false); // Hide loader even if there's an error
  };

  useEffect(() => {
    // Simulate a 4-second loader
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
      scrollToIframe();
    }, 4000);

    return () => clearTimeout(loaderTimeout);
  }, []); // Empty dependency array ensures the effect runs only once

  const { currentHeight } = useWindowResize();

  const [newHeight, setNewheight] = useState(600);
  useEffect(() => {
    if (currentHeight) {
      const height = currentHeight * 0.85; // 85% of viewport height
      setNewheight(height);
      console.log(height);
    }
  }, [currentHeight]);

  useEffect(() => {
    const resizeHandler = () => {
      if (iframeRef.current) {
        let currentHeight = window.innerHeight;

        const height = currentHeight * 0.85; // 85% of viewport height
        iframeRef.current.style.height =
          height > 550 && height < 600
            ? 650
            : height > 600 && height < 700
            ? 650
            : height > 700
            ? 600
            : height + "px";
      }
    };

    window.addEventListener("resize", resizeHandler);
    resizeHandler(); // Initial resize
    return () => window.removeEventListener("resize", resizeHandler);
  }, []); // Empty dependency array ensures the effect runs only once

  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <div style={{ marginTop: "20px" }}>
      {showLoader ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "85vh",
          }}
        >
          <div
            style={{
              border: "8px solid #f3f3f3",
              borderTop: "8px solid #3498db",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              animation: "spin 1s linear infinite",
            }}
          ></div>
        </div>
      ) : (
        <div ref={iframeRef}>
          <iframe
            src={apiUrl}
            title="Qsense Demo"
            style={{
              width: "100%",
              height: `${
                newHeight < 550
                  ? "500px"
                  : newHeight > 550 && newHeight < 600
                  ? "530px"
                  : newHeight > 6000 && newHeight < 700
                  ? "580px"
                  : "590px"
              }`,
            }}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
          ></iframe>
        </div>
      )}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default MyComponent;
