import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import LogoImage from "images/logo.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitters-icon.svg";
// import { ReactComponent as TwitterIcons } from "images/twitters-icon.png";

import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import Logo2 from "images/RecoSenseLogo.png";
import { useNavigate } from "react-router-dom";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`container mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300 cursor-pointer`;

const SubscribeNewsletterColumn = tw(
  Column
)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`  text-sm font-medium text-gray-600`;
const SubscribeForm = tw.form`lg:mt-4  text-sm sm:flex  sm:max-w-none mx-auto `;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(
  PrimaryButtonBase
)`mt-4 sm:mt-0 w-full sm:w-auto  bg-[#1E91FF] rounded sm:rounded-l-none px-8 py-3`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {

const navigate = useNavigate();


const openAboutUs = () => {
  const url = process.env.REACT_APP_ABOUT_US;
  window.open(url, '_blank');
};


const onClickPrivacy = ()=> {
  navigate("/privacy-policy")
}

const onClickTerms = ()=> {
  navigate("/terms-of-use")
}


const openContactUs = () => {
  const url = process.env.REACT_APP_CONTACT_US;
  window.open(url, '_blank');
};


  return (
    <Container>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>About QSENSE</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <div>
                  <b>AI Product of Recosense Labs Inc</b>
                  <br />
                  An AI copilot for data discovery, powered by advanced natural 
                  <br /> language query (NLQ) understands your queries and uses machine{" "}
                  <br />
                  learning algorithms to give you quick and meaningful responses.  <br />
                  Qsense learns from the data extracted, understands the context 
                  <br /> with data correlation & forms a knowledge graph through which <br />
                  you can accelerate data discovery using conversational queries.
                </div>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="#">FAQs</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Support</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">About Us</Link>
              </LinkListItem> */}
            </LinkList>
          </Column>
          {/* <Column>
            <ColumnHeading>Product</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#">Log In</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Personal</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Business</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Team</Link>
              </LinkListItem>
            </LinkList>
          </Column> */}
          {/* <Column>
            <ColumnHeading>Press</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#">Logos</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Events</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Stories</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Office</Link>
              </LinkListItem>
            </LinkList>
          </Column> */}
          <Column>
            <ColumnHeading>Quick Links</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link onClick={()=> openAboutUs()}>
                  About Us
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link onClick={()=> onClickPrivacy()}>
                  Privacy Policy
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link onClick={()=> onClickTerms()}>
                  Terms Of Use
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link onClick={()=> openContactUs()}>
                  Contact Us
                </Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="#">Disclaimer</Link>
              </LinkListItem> */}
            </LinkList>
          </Column>
          <SubscribeNewsletterColumn>
            {/* <SubscribeNewsletterContainer>
              <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
              <SubscribeText>
To get frequent updates join our Newsletter              </SubscribeText>
              <SubscribeForm method="get" action="#">
                <Input type="email" placeholder="Your Email Address" />
                <SubscribeButton type="submit">Subscribe</SubscribeButton>
              </SubscribeForm>
            </SubscribeNewsletterContainer>
            <br/> */}
            <div tw="flex items-center">
              <h1 tw="mr-2  ">
                <b tw="text-blue-500">Contact Us </b>: sales@qsense.ai
              </h1>
            </div>
          </SubscribeNewsletterColumn>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={Logo2} />
            <Link href="https://www.recosenselabs.com">
              <LogoText href="google.com">Recosense Labs Inc.</LogoText>
            </Link>
          </LogoContainer>
          <CopywrightNotice>
            &copy; 2024 Recosense Labs Inc. All Rights Reserved.
          </CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink href="https://www.linkedin.com/company/recosenselabs"  target="_blank">
              <LinkedinIcon />
            </SocialLink>
            <SocialLink href="https://www.facebook.com/RecoSenseLabs"  target="_blank">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com/recosenselabs"  target="_blank">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/@recosenseinfosolutions2803" target="_blank">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
