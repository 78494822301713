import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro"; 
import GitHubButton from "react-github-btn";
import Footer from "../../querysense-landing-page/src/components/footers/FiveColumnWithInputForm"
import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import light from "../../querysense-landing-page/src/components/headers/light.js";
import logo from "images/logo.svg";
import logo2 from "../src/images/RecoSenseLogo.png";
import Header from "../../querysense-landing-page/src/components/headers/light.js";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline`;
const HeroRow = tw(Row)`max-w-screen-lg  flex-col justify-between pb-20 lg:pb-24 mx-auto`; 

const Heading = tw(HeadingBase)` text-primary-900 leading-snug`;
const Description = tw(DescriptionBase)`mt-4 lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  window.gtag("js", new Date());
  window.gtag("config", "UA-45799926-9");

  const downloadUrl = "/treact-ui.zip"
  // React.useEffect(() => {
  //   var iframe = document.createElement("iframe");
  //   iframe.style.display = "none";
  //   iframe.src = downloadUrl
  //   document.body.appendChild(iframe);
  // }, [])

  return (
    <>
    <div >
    <Header /></div>
    <>
    {/* <AnimationRevealPage disabled> */}
      <Container tw="-mx-8 -mt-8 pt-8 px-8 flex justify-between">
        <Content2Xl>
          {/* <NavRow> */}
          {/* <LogoLink href="/">
            <img style={{ height: "78px", width: "157px" }} src={logo2} alt="" />
            Qsense
          </LogoLink> */}
          {/* <div tw="flex flex-col lg:flex-row items-center">
<NavLink target="_blank" href="https://owaiskhan.me/post/free-tailwindcss-react-ui-kit">
  License & Usage
</NavLink>
<NavLink target="_blank" href="https://twitter.com/owaiswiz">
  Twitter
</NavLink>
<NavLink target="_blank" href="https://owaiskhan.me">
  Who Am I ?
</NavLink>
<PrimaryNavLink target="_blank" href="https://gum.co/QaruQ">
  Download Now
</PrimaryNavLink>
</div> */}
          {/* </NavRow> */}
          <HeroRow>
            <Heading>Privacy Policy</Heading>
            {/* <Description tw="mt-12">This Privacy Policy (“Privacy Policy”) explains how information about you or your clients (“Clients,” together “you”, or “yours”) is collected, used, and disclosed by Recosenselabs, Inc. and its affiliates (collectively, “Recosense,” “we,” “us” or “our”) when you access or use our websites (including, without limitation, https://qsense.ai and any other websites owned or controlled by us that link to this Privacy Policy) (each, a “Website” and collectively, the “Websites”) and any associated features, functionalities, user interfaces, downloads, applications, digital properties and other online services (collectively, the “Services”) Services. By accessing or using our Services, you agree to our collection, use, and disclosure of your information as outlined in this Privacy Policy.  */}
            {/* <NavLink href={downloadUrl} download>click this link</NavLink>. */}
            {/* </Description> */}
            <div tw="mt-12 ">
              This Privacy Policy (<b>“Privacy Policy”</b>) explains how information about you or your clients (“<b>Clients</b>,” together “<b>you</b>”, or “<b>yours</b>”) is collected, used, and disclosed by Recosenselabs, Inc. and its affiliates (collectively, “Recosense,” “we,” “us” or “our”) when you access or use our websites (including, without limitation, https://qsense.ai and any other websites owned or controlled by us that link to this Privacy Policy) (each, a “Website” and collectively, the “Websites”) and any associated features, functionalities, user interfaces, downloads, applications, digital properties and other online services (collectively, the “Services”) Services. By accessing or using our Services, you agree to our collection, use, and disclosure of your information as outlined in this Privacy Policy.
              <br /><br />
              This Privacy Policy applies to your use of our Services, and covers only information collected in connection with your access to and use of our Services. Please read this Privacy Policy carefully. By continuing to interact with our Services, you are consenting to the practices described herein.
              <br /><br />
              In addition to acting as a data controller in certain situations, we may also act as a data processor on behalf of some professional or business customers. As a data processor, we process personal information at the direction of data controllers. Those data controllers have their own obligations under applicable data privacy laws, and we are not responsible for their information practices. When we act as a data processor, this Privacy Policy does not apply. Instead, you should refer to the customer’s privacy notice if you would like to exercise your privacy rights or have any questions about how they handle your personal information.

              <br /><br />
              <b>  I. Personal Information We Collect and How We Collect It</b>
              <br /><br />
              When you access or use our Services, we collect and store certain information about you or your Clients, including your or your Clients’ “Personal Information.” Personal Information is information that, alone or in combination with other information in our possession, could be used to personally identify you or your Clients. We collect the following categories of Personal Information and other information when you access our Services, either when you provide it directly to us, or automatically, through the use of our or third-party vendor technologies.  We also collect these categories from our service providers, marketing and advertising networks, and other third parties, such as your employer, financial institution, insurance carrier, or benefits administrator.
              <br /><br />
              Please note that the Personal Information we collect depends on your relationship with us. For example, we generally collect all or most of the below categories of information about your Clients in order to provide the tax related offering of our Services. If you are accessing our Services in order to assist a Client, we may not collect all of the below categories.
              <br /><br />
              Contact Information: Your name, address, phone number, email address, and telephone number. This may include your personal and business contact information.
              <br /><br />
              Demographic Information: Date of birth, marital status, and household details. This may also include certain life events that may affect your tax liability, such as disability or employment status.<br /><br />
              Financial Information: Income, details about your assets and financial transactions, and bank account numbers and associated information. This also includes your taxpayer information, such as withholding selections and dependents.<br /><br />
              Identification: Social Security number, driver’s license number, taxpayer identification number, or other government-issued identification number.
              Communications: Your messages, emails, or other communications with us, such as when you send us business inquiries or send us messages through the Services. Depending on how you interact with us, this may also include recordings of your telephone conversations with us, such as if you call us for customer service purposes.<br /><br />
              Professional Information: Your company, job title, industry affiliations, and other similar information.<br /><br />
              Account credentials: Your username and password if you choose to create an account with us.<br /><br />
              Identifiers and Website Activity: Internet Protocol (“IP”) address, device ID, and similar technological identifiers. This includes details of your visits to our Platform and information generated in the course of the use of our Services, such as the timing, frequency, and pattern of use, click data, other communication data, and how you interact with the Services and our marketing communications, including the resources that you access in the Services. This also includes the website you visited before coming to our Platform and the websites you went to after leaving our Platform.<br /><br />
              Network Activity: This includes details regarding the device(s) and browser(s) you use to access our Services, including your operating system and browser type.<br /><br />
              Social Media Information: Account names, handles, biographies, and other details that you make available on social media networks.<br /><br />
              Geolocation: Your general geographic location, e.g., your state, county, or city that is associated with your IP address.<br /><br />
              Other information you may provide to us, such as when you upload or provide us with documents.<br /><br />
              Inferences we make based on the above.<br /><br />
              <b>II. How We Use Your Personal Information</b><br /><br />
              We may use Personal Information that we collect about you for the following purposes:<br /><br />
              To develop and provide you with the Services, including to maintain, repair, analyze, and improve the content and functionality of the Services, personalize, and enable your use of the Services, and develop new products and features.<br /><br />
              To protect Qsense, our users, and the public, and comply with applicable law, regulation, or legal process, including to validate user information for fraud and risk detection purposes, resolve disputes and protect the rights of users and third parties, respond to claims and legal process (such as subpoenas and court orders), fulfill our reporting obligations, monitor and enforce compliance with our contracts, including the Terms of Use, detect, prevent, or stop any activity that may be illegal, unethical, or legally actionable.<br /><br />
              To operate our business, including to process payment transactions, manage and enforce contracts with you or third parties, manage our corporate governance, compliance, and auditing practices, and generate anonymized or aggregated data.<br /><br />
              To communicate with you, including to respond to requests or questions you submit, send surveys about the Services, or provide notices.<br /><br />
              To advertise and market to you, including to inform you of our or our partners’ products, services, features or promotions, provide you with newsletters, announcements, and other content, or develop “interest-based” or “personalized advertising,” including through cross-device tracking. If we do so, each marketing communication we send you will contain instructions permitting you to “opt-out” of receiving future marketing communications. In addition, if at any time you wish not to receive any future marketing communications or you wish to have your name deleted from our mailing lists, please contact us as indicated below.<br /><br />
              For any other purpose for which you, your employer, or your employer’s agent expressly authorize us to use your information or as we may otherwise state when collecting your Personal Information.<br /><br />
              To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Information held by us about our customers is among the assets transferred.<br /><br />
              <b>III. When and with Whom We Disclose Your Personal Information</b><br /><br />
              We disclose the Personal Information identified above with the categories of third parties listed below for the purposes described above in Section II, unless otherwise noted.<br /><br />
              Third Parties in the Financial and Tax Industry, including tax preparers, banks, credit bureaus, and financial institutions as necessary to provide the Services or that you have otherwise identified to us and authorized to access your information.<br /><br />
              Service Providers that we engage to perform certain functions on our behalf, such as vendors that provide services like website hosting, database management, customer service, data analytics, and tax preparers who work with Recosenselabs.<br /><br />
              Corporate Affiliates, including our subsidiaries, parent entities, and others in our corporate structure.
              Government Agencies, including taxing authorities and their authorized collectors, in the countries in which we operate, only as necessary for us to provide you with the Services.
              Advertising Networks, including social media platforms, that deliver advertisements about us to you, including companies that utilize cookies and similar online tracking technologies in order to deliver advertisements that are personalized to you when you visit other websites (“interest-based advertising” or “personalized advertising”).
              Third Parties in the Context of a Merger or Similar Business Transaction, as described above.<br /><br />
              Other third parties
              for legal reasons, including with companies that verify your identity for us and detect fraud, with legal and financial advisors, auditors, examiners, and certain (including potential) investors.<br /><br />
              to comply with applicable law, regulation, or legal process, including to comply with legal process, such as a court order or subpoena (including in a country other than your home country), protect your, our, or others’ rights, property, or safety, and enforce our policies or contracts and collect amounts owed to us.<br /><br />
              to manage any referral program, including emailing potential customers that you have referred to us, which reference your name as the referral source.<br /><br />
              to further public policy or research goals, including publishing reports that incorporate aggregated and/or de-identified information.<br /><br />
              for any other purpose and to any other person with whom you, your employer, or your employer’s agent expressly authorize us to share your information.<br /><br />
              <b>IV. Your Privacy Choices and Rights</b><br /><br />
              Your Privacy Choices<br /><br />
              The privacy choices you may have about your Personal Information are determined by applicable law and are described below.<br /><br />
              Email and Text Messages. We may send promotional email and text messages. You can opt out of our promotional emails by using the unsubscribe link located at the bottom of our promotional emails or contacting us as described below. You can opt out of text messages from us by replying “STOP” or contacting us as described below. If you decide to opt-out, we may still send you non-promotional communications related to your use of the Services and messages about your account.<br /><br />
              Mobile Notifications. We may send you push notifications through our mobile app. You can opt out from receiving push notifications by changing the settings on your mobile device.<br /><br />
              “Do Not Track.” Do Not Track (“DNT”) is a privacy setting you can set on some web browsers that signals to websites like ours that you don’t want your online activities to be tracked. At this time, we do not respond to DNT signals sent to us by your web browser.<br /><br />
              Cookies and Interest-Based Advertising. You may control the use or functionality of some cookies by changing the settings on your browser. However, if you block these, certain Services (or function or features) may not work properly. Please note you must separately opt out in each browser and on each device. Depending on the advertising or analytics provider, they may offer their own opt-out method related to their products, such as Google. For more information, visit here.<br /><br />
              <b>Your Privacy Rights</b><br /><br />
              Depending on where you reside and in accordance with applicable law, you may have various rights with regard to your Personal Information. To exercise any applicable rights please contact us using the resources in the “Contact Information” Section below.<br /><br />
              Special Information for California Residents<br /><br />
              Under California’s Shine the Light Law, you have the right to request information from us regarding how we may share Personal Information with third parties for their direct marketing purposes. Under California’s Shine the Light Law, you have the right to send us a request at the designated email address listed below to receive the following information:
              the categories of Personal Information we disclosed to third parties for their direct marketing purposes during the preceding calendar year;
              the names and addresses of the third parties that received the information; and
              if the nature of the third party’s business cannot be determined from their name, examples of the products or services marketed.<br /><br />
              <b>Special Information for Nevada Residents</b><br /><br />
              Residents of the State of Nevada have the right to opt out of the sale of certain pieces of their information to other companies who will sell or license their information to others.<br /><br />
              <b>V. Important Information</b><br /><br />
              <b>A. Security</b><br /><br />
              We employ commercially reasonable administrative, physical, and technical measures designed to protect your information from unauthorized access and to comply with applicable privacy laws in the states and countries in which we operate. However, despite these efforts, no security measures are perfect or impenetrable and no method of data transmission can be guaranteed to prevent any interception or other type of misuse. We also depend on you to protect your information. If you become aware of any breach of security or privacy, please notify us immediately. To the fullest extent permitted by applicable law, we do not accept liability for unauthorized disclosures.<br /><br />
              <b>B. International Data Transfers</b><br /><br />
              All information processed by us or our service providers may be transferred, processed, or stored anywhere in the world, including in countries that may have data protection laws that are different from the laws where you live. Your information may be accessible to the courts, law enforcement, and national security authorities of the United States or other jurisdictions. We endeavor to safeguard your information consistent with the requirements of applicable laws.<br /><br />
              <b>C. Links to Other Sites</b><br /><br />
              This Privacy Policy only covers the privacy practices of Recosenselabs Inc.. It does not apply to the practices of third-party websites, services, or applications, even those who we have partnered or integrated with. Third-party services handle your information in accordance with their own practices and privacy policies. We are not responsible for their policies, practices, or handling of your information.<br /><br />
              <b>D. Our Policy Toward Children</b><br /><br />
              The Services are not directed to children under 18. However, if a child under the age of 18 is a dependent of a customer or otherwise relevant to a customer’s tax filing, we may collect information about the child (solely as needed to provide the Services) from or at the direction of the child’s parent or legal guardian, or from third parties with information potentially relevant for tax purposes. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy. No one under age 18 may provide any Personal Information to or on the Services. In the event that we learn that we have collected Personal Information from a child under age 18, we will delete that information as quickly as possible. If you believe that we might have obtained any information directly from a child under 18, please contact us as set forth below.<br /><br />
              <b>E. Changes to this Privacy Policy</b><br /><br />
              Any information that we collect is subject to the Privacy Policy in effect at the time such information is collected. We may, however, modify and revise our Privacy Policy from time to time. If we make any material changes to this policy, we will notify you of such changes in accordance with law, which may include posting them on the Platform, informing you through the Services, or sending you an email or other notification, and we will indicate when such changes will become effective. By continuing to access or use the Services after those changes become effective, you agree to be bound by the revised policy.<br /><br />
              <b>H. Contact Information</b><br /><br />
              If you have any questions about our privacy practices or this Privacy Policy, or to exercise your privacy rights or choices as detailed in this Privacy Policy, please contact us at:<br /><br />
              <b>Recosenselabs Inc.</b><br /><br />
              <b>support@recosenselabs.com</b>

              <span tw="font-bold"></span>
              {/* <span tw="font-bold">We collect several different types of information for various purposes to provide and improve our service to you.</span>. */}
            </div>
            {/* <div tw="mt-12 text-center">
Personal Data: While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:

Email address
First name and last name
Phone number
Address, State, Province, ZIP/Postal code, City
Cookies and Usage Data
Usage Data: We may also collect information on how the Site is accessed and used ("Usage Data").

</div> */}
          </HeroRow>
        </Content2Xl>
      </Container>
    {/* </AnimationRevealPage> */}
    <Footer /></></>
  );
};
