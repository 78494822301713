import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(
  Container
)`-mx-8 px-8  text-gray-800 p-20`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-gray-400 hover:border-gray-600 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-700`;

export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "",
  faqs = [
    {
      question: " Can I try Qsense for free?",
      answer:
        "Yes, you can sign up for a complimentary 7-day trial to explore the features and benefits of Qsense.",
    },
    {
      question: "Are API’s available for use in Qsense?",
      answer:
        "Qsense offers a simple signup process using your Gmail account. For developers and programmatic users, API’s are available with documentation after login.",
    },
    {
      question: " What payment methods can I use for Qsense?",
      answer:
        "You can conveniently use a credit card as the preferred payment method for Qsense ",
    },

    {
      question: "Who typically uses Qsense?",
      answer:
        "Qsense is utilized by Back-office Finance teams for streamlined invoice handling and management of vendor contracts, as well as by CPAs and CAs for tax statement analysis, identifying patterns, detecting errors, and ensuring efficient accounts receivables/payables.",
    },

    {
      question: " How many documents can Qsense process per minute? ",
      answer:
        "Qsense can process approximately 50 pages per minute, ensuring efficient document handling. ",
    },

    {
      question:
        " What types of documents can Qsense process for tax preparation? ",
      answer:
        "Qsense can process a variety of documents including scanned documents, PDFs, and images, facilitating seamless tax preparation.",
    },
    {
      question: " How do I perform corporate tax preparations using Qsense?",
      answer:
        "To initiate corporate tax preparations, you can begin by testing the platform with sample documents provided. Click on tax documents and invoices to extract data, or upload your own documents such as 1040, W2 forms, and 1099s for data extraction.",
    },
    {
      question: "How does Qsense protect my data? ",
      answer:
        "Data privacy is a top priority for Qsense, aligning with ISO, GDPR, and SOC2 standards. Robust security protocols ensure the privacy and accuracy of your data.",
    },
  ],
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq
        key={index}
        onClick={() => toggleQuestion(index)}
        id={`faq-${index}`}
      >
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 },
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: {
              opacity: 1,
              height: "auto",
              marginTop: "16px",
              display: "block",
            },
            collapsed: {
              opacity: 0,
              height: 0,
              marginTop: "0px",
              display: "none",
            },
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: faqs.map((faq) => ({
              "@type": "Question",
              name: faq.question,
              acceptedAnswer: {
                "@type": "Answer",
                text: faq.answer,
              },
            })),
          })}
        </script>
      </Helmet>
      <PrimaryBackgroundContainer style={{background:'#f1f1f1'}}>
        <ContentWithPaddingXl>
          <HeadingContainer>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
          </HeadingContainer>
          <FaqsContainer>
            <FaqsColumn>{faqCol1}</FaqsColumn>
            <FaqsColumn>{faqCol2}</FaqsColumn>
          </FaqsContainer>
        </ContentWithPaddingXl>
      </PrimaryBackgroundContainer>
    </>
  );
};
