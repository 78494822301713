import React, { useRef, useEffect,useState } from 'react';
import style from "./download.css"
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import mockupImageSrc from "images/app-mockup.png";
import Hero from "components/hero/TwoColumnWithVideo.js";
import video from "../../images/qsvideo.MP4";
import video2 from "../../images/qsvid2.mp4";

import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import {
  ContentWithPaddingXl,
  Container as ContainerBase,
} from "components/misc/Layouts";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import appleIconImageSrc from "images/apple-icon.png";
import googlePlayIconImageSrc from "images/google-play-icon.png";

const Container = tw(ContainerBase)` -mx-8`;
const Content = tw(ContentWithPaddingXl)``;
const Row = tw.div`px-8 flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left justify-center`;
  const videoUrl = "https://www.youtube.com/embed/3FltO3Pk4rc";
const ColumnContainer = tw.div`max-w-2xl`;
const TextContainer = tw(ColumnContainer)``;
const Text = tw(SectionHeading)`text-gray-100 lg:text-left max-w-none text-3xl leading-snug`;
const Subheading = tw(SubheadingBase)`text-yellow-500 mb-4 tracking-wider`;

const LinksContainer = tw.div`mt-8 lg:mt-16 flex flex-col items-center sm:block`;
const Link = styled.a`
  ${tw`w-56 p-3 sm:p-4 text-sm sm:text-base font-bold uppercase tracking-wider rounded-full inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-gray-100 hocus:bg-gray-300 text-gray-900 hocus:text-gray-900 shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`}
  img {
    ${tw`inline-block h-8 mr-3`}
  }
  span {
    ${tw`leading-none inline-block`}
  }
`;

const ImageContainer = tw(ColumnContainer)`mt-16 lg:mt-0 lg:ml-16 flex justify-end`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-gray-800 opacity-50`;
const DecoratorBlob2 = tw(SvgDecoratorBlob1)`absolute top-0 right-0 w-80 h-80 transform translate-x-20 -translate-y-64 text-gray-800 opacity-50`;
export default ({
  
  subheading1 = "Faster decisions 1",
  text1 = "Developers all over the world are happily using Treact 1",
  // link1Text1 = "App Store 1",
  // link1Url1 = "http://apple.com",
  // link1IconSrc1 = appleIconImageSrc,
  // link2Text1 = "Google Play 1",
  // link2Url1 = "http://play.google.com",
  // link2IconSrc1 = googlePlayIconImageSrc
  subheading2 = "Faster decisions 2",
  text2 = "Developers all over the world are happily using Treact 2",
  // link1Text2 = "App Store 2",
  // link1Url2 = "http://apple.com",
  // link1IconSrc2 = appleIconImageSrc,
  // link2Text2 = "Google Play 2",
  // link2Url2 = "http://play.google.com",
  subheading3 = "Faster decisions 3", // Add a third subheading
  text3 = "Developers all over the world are happily using Treact 3",
  // link1Text3 = "App Store 3",
  // link1Url3 = "http://apple.com",
  // link1IconSrc3 = appleIconImageSrc,
  // link2Text3 = "Google Play 3",
  // link2Url3 = "http://play.google.com",
  subheading4 = "Faster decisions 3", // Add a third subheading
  text4 = "Developers all over the world are happily using Treact 3",
  link1Text4 = "App Store 3",
  link1Url4 = "http://apple.com",
  link1IconSrc4 = appleIconImageSrc,
  link2Text4 = "Google Play 3",
  link2Url4 = "http://play.google.com",
  pushDownFooter = false,
}) => {
  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleScroll = () => {
    if (videoRef.current) {
      const { top, bottom } = videoRef.current.getBoundingClientRect();
      const isVisible = top < window.innerHeight && bottom >= 0;
      setIsVideoPlaying(isVisible);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isVideoPlaying && videoRef.current) {
      videoRef.current.play();
    } else if (videoRef.current) {
      videoRef.current.pause();
    }
  }, [isVideoPlaying]);
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
       {/* <DecoratorBlob1 /> */}
          {/* <DecoratorBlob2 /> */}
      <Content>
        <br/>
      {/* <h1 style={{fontSize:"40px", color:"white", fontWeight:"800", textAlign:"center"}}><bold>How it works!</bold></h1><br/> */}

      {/* <div className="video-container"> */}
      {/* <iframe
        width="80%" // You can adjust the width and height as needed
        height="600px"
        src={videoUrl}
        title="YouTube Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe> */}
      <div
       style={{
        display: 'flex',
        justifyContent: 'center',
        // alignItems: 'center',
        // height: '100vh',
      }}
      className="App">
      <video
      ref={videoRef}
  style={{ maxWidth: '100%', maxHeight: '100%' }}
  width="90%" height="900px"
  loop
  autoPlay
  // muted
>
  <source src={video2} type="video/mp4" />
</video>

      </div>
    {/* </div> */}

      
        {/* Add a second row with the same structure */}
      
        {/* <DecoratorBlobContainer>
         
        </DecoratorBlobContainer> */}
        <br/><br/>
      </Content>
    </Container>
  );
};
